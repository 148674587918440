import React from "react"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import { overlay } from "../styles/util"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`web development`, `guitar`, `music`, `gatsby`, `application`, `react`]} />
    <section>
      <Img css={overlay} alt="Photo of Hunter walking through some snow" fluid={data.file.childImageSharp.fluid} />
    </section>
    <section css={styles.section}>
      <p>Howdy, I'm Hunter MacDermut. I do front-end web development, make educational videos on music theory, and play guitar. Take a look around for more info about each and feel free to get in touch!</p>
      <div css={styles.links}>
        <a css={styles.a} href="mailto:whosebluesanyway@gmail.com"><i className="fas fa-envelope"></i></a>
        <a css={styles.a} href="https://www.github.com/huntermacd"><i className="fab fa-github"></i></a>
        <a css={styles.a} href="https://www.youtube.com/user/acpgapex"><i className="fab fa-youtube"></i></a>
      </div>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
  links: css`
    max-width: 320px;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
  `,
  a: css`
    font-size: 3rem;
    margin-right: 16px;

    &:last-of-type {
      margin: 0;
    }
  `
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "huntersnow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
