import { css } from "@emotion/core"

export const overlay = css`
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    background-color: steelblue;
    opacity: 0.5;
    position: absolute;
    z-index: 1;
  }
`
