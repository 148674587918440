import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Header = ({ siteTitle }) => (
  <header css={styles.header}>
    <div css={styles.navContainer}>
      <h1>
        <Link to="/" activeClassName="active">{siteTitle}</Link>
      </h1>
      <nav>
        <ul css={styles.linkList}>
          <li>
            <Link to="/web-development/" activeClassName="active">Dev</Link>
          </li>
          <li>
            <Link to="/anyone-can-play-guitar/" activeClassName="active">ACPG</Link>
          </li>
          <li>
            <Link to="/music/" activeClassName="active">Music</Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

const styles = {
  header: css`
    z-index: 2;
    position: relative;
  `,
  navContainer: css`
    margin: 0 auto;
    max-width: 960px;
    padding: 1.45rem 1.0875rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-family: 'Major Mono Display', monospace;
      text-transform: lowercase;
      white-space: pre-wrap;
      line-height: 1;
      font-size: 1rem;

      a {
        text-decoration: none;
        color: black;
        font-weight: bold;

        &.active,
        &:hover {
          text-decoration: overline;
        }
      }
    }
  `,
  linkList: css`
    display: flex;
    margin: 0;

    li {
      list-style-type: none;
      font-family: 'Major Mono Display', monospace;
      text-transform: lowercase;
      line-height: 1;
      margin: 0 1rem 0 0;

      &:last-of-type {
        margin: 0;
      }

      a {
        text-decoration: none;
        color: black;
        font-weight: bold;

        &.active,
        &:hover {
          text-decoration: overline;
        }
      }
    }
  `,
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
